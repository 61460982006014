// Generated on Fri Feb 28 13:01:36 UTC 2025

export const environment = {
  production: true,
  envName: "prod",

  apiKeyGoogleMaps: "AIzaSyCxYTvKfyUN5CTRzAREsdCJGGy26e1sNio",
  gaTrackingId: "G-EGHMZ0SQWS",
  bucketS3: "sanofi-elefight-input-prod",

  apiGatewayId: "kdh2y1wgf5",
  apiUrl: "https://www.elefight.com/v1",
  redirectUrl: "https://www.elefight.com/v1/qrcode/access",
  inputPath: "/public/",
  i18nPath: "https://sanofi-elefight-input-prod.s3-eu-west-1.amazonaws.com/public/assets/i18n/",

  dataPath: "COUNTRY/FRANCE/2020/",
  csvFilename: "Real_Data_2020_France",

  identityPoolId: "eu-west-1:50ded8f4-3532-4820-9fc8-1933aa58ef12",
  region: "eu-west-1",
  userPoolId: "eu-west-1_dJf83ccDg",
  userPoolWebClientId: "1uekslb60v504aisbrok5o4uf9",

  cognito_idp_endpoint: ""
};
